export const en = {
    "skills": "Competence",
    "systematicTasks": "Systematic tasks",
    "issues": "Deviations",
    "issuesAccidents": "Accident/incident/risk observation",
    "CompetenceUser": "User competence",
    "objects": "Objects",
    "improvements": "Improvements",
    "chemicals": "Chemicals",
    "docs": "Documents/Routines",
    "actionsTaken": "Direct actions",
    "actionsTakenInfo": "Direct actions (What has been done to minimize further penalties?)",
    "addNewIssue": "New deviation",
    "addNewAccident": "New accident/incident/risk observation",
    "mySkills": "My competence/education",
    "screenIsNarrow": "Your screen has entered mobile width, click button to switch to mobile mode",
    "education": "Education",
    "educations": "Educations",
    "matrix": "Matrix",
    "matrices": "Matrices",
    "engagementStatistics": "Engagement statistics",
    "switchToMobile": "Switch to mobile",
    "myTasks": "My tasks",
    "newTask": "New task",
    "new": "New",
    "signedInAs": "Signed in as",
    "print": "Print",
    "validFrom": "Valid from",
    "validTo": "Valid to",
    "competenceLevel": "Competence level",
    "positions": "Positions",
    "position": "Position",
    "noPositions": "No positions",
    "noSkills": "No skills",
    "files": "Files",
    "file": "File",
    "addFile": "Add new file",
    "Bad Request": "Bad request",
    "Network Error": "Network error",
    "addFirstFile": "Add first file",
    "heldBy": "Held by",
    "scope": "Scope",
    "plannedDate": "Planned date",
    "dateCompleted": "Date held",
    "utvärdera": "Evaluate",
    "utförd": "Performed",
    "requiredSkills": "Required competence",
    "evaluateCourse": "Evaluate",
    "location": "Location",
    "save": "Save",
    "cancel": "Cancel",
    "reviewCourseText": "Describe how you experienced the course contents. (Provider, Location, Scope, Contents)",
    "satisfiedPercentage": "Enter how satisified you are with the course as a percentage (1-100)",
    "wouldRecommend": "Would you recommend this course to someone else?",
    "lessonsLearned": "What are some of the lessons learned?",
    "course": "Course",
    "news": "News",
    "object": "Object",
    "readMore": "Read more",
    "readLess": "Read less",
    "registerNew": "Register new",
    "analysisAction": "Analysis/Remedy (performed by responsible)",
    "analysis": "Cause Analysis",
    "analysisInfo": "Cause Analysis (Find the root cause! For example, use the 5 whys method)",
    "incidentConditions": "Conditions that were as usual but that may have influenced the incident: (Check all that apply)",
    "resolvingAction": "Corrective action",
    "resolvingActionInfo": "Corrective action (What should be done to prevent this from happening again?)",
    "absenceTimeType.-1": "Not specified",
    "absenceTimeType.0": "No absence",
    "absenceTimeType.1": "1-3 days",
    "absenceTimeType.2": "4-14 days",
    "absenceTimeType.3": "Longer absence",
    "absenceTimeType.4": "Death",
    "feedbackReporter": "Send Feedback with completed action via email to the person who reported (email is only sent when it is checked)",
    "feedbackImprovementIdeaReporter": "Send feedback with new status via email to the person who reported",

    "editClothesSize": "Change clothes size",
    "editIce": "Change next of kin",

    "iceListHeader": "Next of kin list",
    "iceFormHeader": "Next of kin",
    "userIce1": "Next of kin 1",
    "userIce2": "Next of kin 2",

    "userAuthFormHeader": "Change password",
    "oldPassword": "Current password",
    "newPassword": "New password",
    "confirmNewPassword": "Confirm new password",
    "passwordLength": "Password must be at least 12 characters long",
    "passwordUpperCase": "Password must contain at least one uppercase letter",
    "passwordLowerCase": "Password must contain at least one lowercase letter",
    "passwordNumber": "Password must contain at least one number",
    "passwordSpecialChar": "Password must contain at least one special character",
    "confirmPasswordRequired": "You must confirm the new password",
    "passwordsDoNotMatch": "The passwords do not match",

    "supplierReply": "The supplier will answer",
    "weReply": "We will answer",
    "altEmail": "Alternative email",
    "deviationAnswer": "Answer the deviation",
    "workAuthorityForm": "The Swedish Work Environment Authority and the Swedish Social Insurance Agency form for reporting accidents, serious incidents and work-related injuries.",
    "onlyAccident": "Section for accidents adapted for sensitive information about the injured person. After registration, the fields are only visible to those who are admins in the deviation module.",
    "invalidEmail": "Invalid email",
    "employmentType.-1": "Not specified",
    "employmentType.0": "Permanent employee",
    "employmentType.1": "Time-limited employment",
    "employmentType.2": "Self-employed/family member",
    "employmentType.3": "Labour market policy programme",
    "employmentType.4": "Student (non-trainee employee)",
    "employmentType.5": "Temporary agency staff",
    "causeOfAccident.-1": "Not specified",
    "causeOfAccident.0": "Damaged by something else in motion (machine parts, flying/falling object, throw from hand-held machine)",
    "causeOfAccident.1": "Handling damage, handled object (tool slipping, stationary objects, sharp edge)",
    "causeOfAccident.2": "Fall, jump, slipping or tripping",
    "causeOfAccident.3": "Misstep, oblique step or nail puncture",
    "causeOfAccident.4": "Burn, corrosion, frostbite, poisoning or lack of oxygen (hypoxia)",
    "causeOfAccident.5": "Vehicle accident or hit",
    "causeOfAccident.6": "Physical overload",
    "causeOfAccident.7": "Injured by person (violence) or animal",
    "causeOfAccident.8": "Electric accident, fire, explosion, blasting or casting",
    "causeOfAccident.9": "Shock or other psychosocial incident",
    "causeOfAccident.10": "Other accidents",
    "causeOfAccidentShort.-1": "Not specified",
    "causeOfAccidentShort.0": "Damaged by something else in motion",
    "causeOfAccidentShort.1": "Handling damage",
    "causeOfAccidentShort.2": "Fall, jump, slipping",
    "causeOfAccidentShort.3": "Misstep, oblique step",
    "causeOfAccidentShort.4": "Burn, corrosion",
    "causeOfAccidentShort.5": "Vehicle accident or hit",
    "causeOfAccidentShort.6": "Physical overload",
    "causeOfAccidentShort.7": "Injured by person/animal (violence)",
    "causeOfAccidentShort.8": "Electric accident, fire",
    "causeOfAccidentShort.9": "Psychosocial incident",
    "causeOfAccidentShort.10": "Other accidents",

    "explanatoryText.altEmail": "If you enter an email address, a deviation management is sent to the specified email address instead of the one in the supplier register. The email address is not saved in the field.",
    "placeholder.customerAccident": "Fill in if it happened at a customer",
    "notifyClosingIssueActivityResponsible": "Send email to responsible in deviation about the closed activity",
    "notifyCreationActivityResponsible": "Send email to responsible about new activity",
    "notifyClosingAccidentActivityResponsible": "Send email to responsible in accident/incident/risk observation about the closed activity",
    "skillsAdmin.positionHeader": "Add/Change positions",
    "skillsAdmin.positionMessageBody": `This is where the organizational chart is created and edited. Think of it as an organizational chart rotated 90 degrees.
Start by registering the position's title and job description, including its responsibilities and authority. "Add new" creates a position at the top level. The "+" button on each row adds a new position at the level below. Once the positions are registered, you can determine the order in the organizational chart by using the "drag-and-drop" function.`,
    "skillsAdmin.userHeader": "Change user",
    "skillsAdmin.userMessageBody": `On this page, all active users that you have in the company are shown. A system admin can add new users on the page Admin > New User.
    To change a user, click the "Change" icon to the left of the user's row.  
    Use "Mass connect users" to connect Department/Group, Location of business, Positions or competencies for several users at the same time.`,
    "skillsAdmin.skillHeader": "Add/Change skills",
    "skillsAdmin.skillMessageBody": `On this page, all competences that you have in the company are shown.`,
    "skillsAdmin.orgChartHeader": "Organizational Overview",
    "skillsAdmin.orgChartMessageBody": `On this page, the company's organizational chart is displayed. By clicking on a role, you can view more information about the selected role and have the option to print the job description.
In the top-right corner of the organizational chart, there is also a function to download a PNG image of the chart.`,

    "deviationSettings.typeMessageHeader": "Change type, error code and cause code",
    "deviationSettings.typeMessageBody": `Deviation type is required in order to register deviations. Subtypes (error code and cause code) are optional to use. 
    To create new deviation types, use "+ Add new". To create subtypes (error code and cause code), click on the "+" next to the type it should be placed under. 
    To view subtypes (error code and cause code), expand the deviation type by clicking on the ">" arrow.`,
    "deviationSettings.accidentSubtypeMessageHeader": "Change risk code",
    "deviationSettings.accidentSubtypeMessageBody": `Risk type are optional to use, To create new risk code, click on the "+" next to the type it should be placed under. 
    To view risk codes, expand the type by clicking on the ">" arrow.`,
    "deviationSettings.extraFieldMessageHeader": "Add extra fields",
    "deviationSettings.extraFieldMessageBody": `Extra fields are optional to use. 
    Enter the field name and field type that you want to appear for this field in the form and in the {{value}} list view. 
    When required are checked, the field must be filled in order to save the form. 
    The field name is unique if the field is deleted the information will still be registered against the title if a field with the same name is created again.`,
    "deviationSettings.incidentMessageHeader": "Change setting on reporting in form",
    "deviationSettings.incidentMessageBody": `Here you can change to your own link for reporting and explanatory text. 
    When the fields are empty, the text and link in the accident/incident/risk observation form is VIQMA System's recommended setting (The Swedish Work Environment Authority and the Swedish Social Insurance Agency form for reporting accidents, serious incidents, and work-related injuries.https://anmalarbetsskada.se/).`,
    "deviationSettings.userResposibleMessageHeader": "Change setting about responsible and safety representative",
    "deviationSettings.userResposibleMessageBody": `Here you can change or limit which users can be responsible in each form. You can also add safety representatives to the accident/incident/risk observation form. When a user is selected, selectable users are customized in the form’s drop-down list for responsible or safety representative. 
    If none is selected or when selected is deleted, all users will be displayed. The safety representative field only appears in the form if there is a selected user.`,
    "deviationSettings.statisticsHeader": "Change setting about statistic on start page and labor cost",
    "deviationSettings.companySettingsHeader": "Change company settings for deviation and accident/incident/risk observation",
    "deviationSettings.statisticsMessageBody": `Here you can change which statistic you want visible on the start page for your company. 
    You can also change the labor cost that is used to calculate the quality deviation cost.`,
    "deviationSettings.companySettingsMessageBody": `Here you can change settings for deviation and accident/incident/risk observation on your company.`,
    "showDeviationPopUp": "Show add new pop up in deviation list",
    "tooltip.showDeviationPopUp": "If checked, a add new deviation/accident/incident/risk observation pop up for your company when navigating to deviations.",

    "reportingMessage": "Explanatory text on reporting",
    "reportingLink": "Link to reporting (should contain the whole link, for example https://www.viqma.se/)",
    "popupInfo.deleteDeviationType": "This row cannot be deleted when it is used in a deviation or has something underlying",
    "popupInfo.deletePosition": "This row cannot be deleted when it has underlying positions",

    "pantSize": "Pant size",
    "shirtSize": "Shirt size",
    "shoeSize": "Shoe size",
    "gloveSize": "Glove size",
    "clothesSizeFormHeader": "Clothes and shoe size",

    "extraField1": "Extra field1 (Enter heading)",
    "extraField2": "Extra field2 (Enter heading)",

    "contactSystemAdmin": "Contact your system admin.",
    "toAddLink": "Click here to add",
    "noIssueTypes": "You are missing deviation types which is required to add new items in this module. ",
    "noDepartments": "You are missing departments which is required to add new items in this module. ",
    "noUsers": "You are missing users which is required to add new items in this module. ",
    "noInternalQuestions": "You are missing internal questions which is required to add new items in this module. ",
    "noExternalQuestions": "You are missing external questions which is required to add new items in this module. ",

    "departmentListHeader": "Handle department/group",
    "editDepartment": "Edit department/group",
    "addDepartment": "Add new department/group",

    "locationListHeader": "Handle location",
    "editLocation": "Edit location",
    "addLocation": "Add new location",

    "collectionId": "Collection id",
    "accidentalEradication": "Accidental release measures",
    "action": "Action plan",
    "actionEmissions": "Action on release",
    "addChemicalDepartment": "Add Chemicals / Department",
    "addChemicalPerDepartment": "Add Chemicals per department",
    "addChemicalPerPlaceOfBusiness": "Add chemical per location of business",
    "addNewRiskText1": "How dangerous are substances in the product if exposed to it? (Choose an alternative.)",
    "addNewRiskText10": "Substance is not volatile or can not form dust",
    "addNewRiskText11": "Because small amounts of the substance can damage while it is easy to get into the air\n                                                                             Measures are important even if a small amount is handled. For short-term handling of one\n                                                                             substance that does not hurt until exposed to excessive amounts, you do not usually need to take\n                                                                             Special protective measures, but work must be done so as not to be exposed to the substance\n                                                                             unnecessarily.",
    "addNewRiskText12": "Review the way the subject is used or occurs and answer the following:",
    "addNewRiskText13": "At which times can workers be exposed to vapors or dust?\n                                                                                <br>\n                                                                                - Can the exposure during the various moments be so extensive that you can be hurt\n                                                                                eventually?\n                                                                                <br>\n                                                                                - What safeguards are needed during different working hours?\n                                                                                <br>\n                                                                                - What unplanned events could lead to high air levels? <br>",
    "addNewRiskText14": "Maintenance / control measures to avoid the events:",
    "addNewRiskText15": "What measures, instructions and equipment are needed for emergency preparedness\n                                                                                 shall be good?",
    "addNewRiskText16": "How dangerous are substances in the product if exposed to it? (Choose an alternative.)",
    "addNewRiskText17": "Own comments",
    "addNewRiskText18": "May be absorbed through the skin and damage to other organs",
    "addNewRiskText19": "The skin is damaged directly (usually because it is highly corrosive).",
    "addNewRiskText2": "Yes",
    "addNewRiskText20": "Skin damage occurs if the substance may remain on the skin. (If yes, choose an option\n                                                                             below.)",
    "addNewRiskText21": "Permanent damage (such as cancer or allergy).",
    "addNewRiskText22": "Transient damage (heals if exposure expires).",
    "addNewRiskText23": "Consider the following:",
    "addNewRiskText24": "At what time of work, workers may be exposed to such skin contact or splashes\n                                                                                 which could hurt in the short or long term?\n                                                                                 <br>\n                                                                                 - What safeguards are needed then?\n                                                                                 <br>\n                                                                                 - What unplanned events can cause splashes or overflow?",
    "addNewRiskText25": "Maintenance / control measures to avoid the events: overcrowding?",
    "addNewRiskText26": "Menade du: Vilka \u00e5tg\u00e4rder, instruktioner och vilken utrustning beh\u00f6vs f\u00f6r att olyckor beredskapen ska vara god?\nWhat measures, instructions and equipment are needed for emergency preparedness\n                                                                             shall be good?",
    "addNewRiskText27": "Ingestion",
    "addNewRiskText28": "How dangerous are substances in the product to get into the mouth?\n                                                                                 <br>\n                                                                                 (Choose an alternative.)",
    "addNewRiskText29": "Acute toxic if you get small amounts",
    "addNewRiskText3": "Own comments",
    "addNewRiskText30": "May damage eventually if trace amounts come into the mouth via, for example, fingers.",
    "addNewRiskText31": "The substance is unsuitable to consume. (Applies to all hazardous substances.)",
    "addNewRiskText32": "Working methods that may lead to ingestion risk are always unsuitable. One should therefore\n                                                                             Make sure that the working methods do not invite you to suck liquids or lick\n                                                                             on something.",
    "addNewRiskText33": "If small amounts can damage, good hygiene is necessary.",
    "addNewRiskText34": "Consider the following:",
    "addNewRiskText35": "Is the working method such that skin contact is avoided?\n                                                                                 <br>\n                                                                                 - Do you hold respiratory protection and gloves clean?\n                                                                                 <br>\n                                                                                 - Have you prevented spreading of the substance to other premises?\n                                                                                 <br>\n                                                                                 - Does the staff wash before breaks and toilet visits?",
    "addNewRiskText36": "Flammability / explosion hazards",
    "addNewRiskText37": "How flammable or explosive are substances in the product? (Choose an alternative.)",
    "addNewRiskText38": "Very or extremely flammable (flash point <23 \u00b0 C)",
    "addNewRiskText39": "Flammable (flash point> 23-60 \u00b0 C)",
    "addNewRiskText4": "The product is already injurious for short-term exposure to small quantities.",
    "addNewRiskText40": "Other classification regarding fire, reaction or explosion hazard",
    "addNewRiskText41": "Review the way the subject is used or occurs and answer the following:",
    "addNewRiskText42": "Can there be fumes that can be ignited or vapors are formed somewhere?\n                                                                                <br>\n                                                                                - Is there a risk of ignition and reaction?\n                                                                                <br>\n                                                                                & Nbsp; & nbsp; - Open fire, hot surfaces or sparks?\n                                                                                <br>\n                                                                                & Nbsp; & nbsp; - other circumstances that can cause the reactions?\n                                                                                <br>\n                                                                                - What changes do you need to take to avoid fire / explosion?\n                                                                                <br>\n                                                                                - What unplanned events can cause a fire or explosion?",
    "addNewRiskText43": "Maintenance / control measures to avoid the events:",
    "addNewRiskText44": "What measures, instructions and equipment are needed for emergency preparedness\n                                                                                 shall be good?",
    "addNewRiskText45": "Accidental release measures",
    "addNewRiskText46": "Are there instructions on how to deal with accidental emissions? (Section 6 of the safety data sheet)",
    "addNewRiskText47": "In case of spillage and leakage, prevent or minimize the harmful effects on persons, property and the environment.",
    "addNewRiskText48": "Consider the following:",
    "addNewRiskText49": "- Does the game volume have a significant impact on the danger?\n                                                                                <br>\n                                                                                - Need personal protective equipment or protective equipment?\n                                                                                <br>\n                                                                                - Is there instructions for emergency action?\n                                                                                <br>\n                                                                                - Are there any need for environmental protection measures, eg. deposition to prevent the product from entering sewers and surface and groundwater?\n                                                                                <br>\n                                                                                - How should games and remediation be handled?\n                                                                                 <br>",
    "addNewRiskText5": "The product is damaged by prolonged or repeated exposure of small amounts.",
    "addNewRiskText50": "Other hazardous properties",
    "addNewRiskText51": "Are there any other hazard information about substances in the product, such as in point 7\n                                                                               or 10 on the safety data sheet?",
    "addNewRiskText52": "May the substance react violently with another substance or under certain conditions (for example\n                                                                             heat, radiation)?",
    "addNewRiskText53": "Can the substance damage certain materials?",
    "addNewRiskText54": "- Can the properties pose a risk in handling?\n                                                                                 <br>\n                                                                                 - What changes need to be made?\n                                                                                 <br>\n                                                                                 - Are there unplanned events that could have dangerous consequences? <br>",
    "addNewRiskText55": "What steps do you need to take to avoid the events:",
    "addNewRiskText56": "- What measures, instructions and equipment are needed for emergency preparedness\n                                                                                 shall be good?",
    "addNewRiskText57": "Summary",
    "addNewRiskText58": "Overall risk assessment (taking into account the classification of the substances, concentration\n                                                                             and amount)",
    "addNewRiskText59": "Possible safeguards",
    "addNewRiskText6": "The product is harmful when exposed to larger amounts.",
    "addNewRiskText60": "Personal protective equipment is required",
    "addNewRiskText61": "Specify specific protective equipment",
    "addNewRiskText62": "Respiratory protection",
    "addNewRiskText63": "Face protection",
    "addNewRiskText64": "Goggles",
    "addNewRiskText65": "Protective gloves",
    "addNewRiskText66": "Protective clothing",
    "addNewRiskText67": "Safety shoes",
    "addNewRiskText68": "Specific waste management is required",
    "addNewRiskText69": "Hazardous waste",
    "addNewRiskText7": "How easy are substances in the product in the air <br> (Choose an option)",
    "addNewRiskText70": "Dangerous goods",
    "addNewRiskText71": "Any topics that are formed",
    "addNewRiskText72": "Date of risk assessment",
    "addNewRiskText73": "Participant in the risk assessment",
    "addNewRiskText74": "Hygienic limit value",
    "addNewRiskText75": "A hygienic limit value (HGV) is the highest acceptable average content of an air pollution in the inhalation air. (Section 8 of the safety data sheet)",
    "addNewRiskText76": "Is there a hygienic limit value?",
    "addNewRiskText77": "Is there a need for a exposure measurement?",
    "addNewRiskText78": "If it is clear that the limit value is not exceeded, then this needs to be justified.",
    "addNewRiskText79": "- Can exposure during various stages become so extensive that you can be damaged in the long term?",
    "addNewRiskText8": "The substance in the product is very volatile or easily forms clouds.",
    "addNewRiskText80": "- What unplanned events could lead to high air congestion?",
    "addNewRiskText9": "Substance is moderately volatile or can emit dust particles quickly.",
    "administrationSP": "administartion(S, P)",
    "airLevel1": "Substance in the product is very volatile or easily forms cloud clouds.",
    "airLevel2": "Substance is moderately volatile or can emit dust particles quickly.",
    "airLevel3": "Substance is not volatile or can not form dust.",
    "areaText": "Area of use",
    "areSubjectsInLists": "Is the subject with listed lists available?",
    "areThereAnyInstructions": "Are there instructions on how to deal with accidental emissions?",
    "attentionToSubject": "Should the subject be noted?",
    "availableAt": "Is on",
    "averageRiskText": "Moderate",
    "browseBtnText": "Browse",
    "browseBtnText2": "Browse...",
    "cancelBtnText": "Cancel",
    "candidateList": "Candidate list",
    "captionAir": "Air",
    "captionAmount": "Quantity",
    "captionDesposal": "Waste",
    "captionFileName": "Filename",
    "captionImgLink": "picture Link",
    "captionUnit": "Unit",
    "captionUploadedByDate": "Uploaded date",
    "captionYear": "Year",
    "caustic": "Caustic",
    "chemicalProdText": "Chemical product consumption is recorded under each product.",
    "chemicalProduct": "Chemical product",
    "chemicalRiskSource": "Chemical risk source",
    "chemicalSubject": "Chemical subject",
    "chemicalSubjectBr": "Chemical <br> subject",
    "chemiDepartmentMatrix": "Matrix Chemicals / Department",
    "classedAsHarmfulGoods": "* Dangerous goods",
    "classedAsHarmfulWaste": "* Hazardous waste",
    "clickToExpandRiskAssessment": "Click the (>) sign in front of the product to see the full risk assessment",
    "close": "Close",
    "closedBy": "Closed by",
    "CLP": "CLP-pictogram",
    "comment": "Comment",
    "commentSupplier": "Comment (Not shared with the supplier)",
    "commentBrCustomer": "Comment <br/> customer requirements",
    "CommentDemand": "Comment Customer Requirements:",
    "confirmDeleteCustomerDemand": "Do you really want to delete this customer requirement?",
    "confirmDeleteDepartmentAssociate": "Do you really want to delete this link to the department?",
    "confirmDeleteFileText": "Do you really want to delete this file?",
    "confirmDeleteSubject": "Are you sure you want to delete this subject?",
    "consumption": "Consumption",
    "consumptionChemical": "Consumption chemical",
    "consumptionChemicals": "Consumption chemicals",
    "copyFromRecentStoredSubject": "Copy from previously posted topics",
    "customerDemandLink": "Link to customer requirements",
    "customerDemands": "Customer requirements",
    "customerText": "Customer",
    "dangerLevel1": "The product is already injurious for short-term exposure to small quantities",
    "dangerLevel2": "The product is damaged by prolonged or repeated exposure of small amounts",
    "dangerLevel3": "The product is damaged by exposure to previous amounts",
    "dangerLevelWhenExposured": "How dangerous are substances in the product if exposed to it?",
    "dangerMouthLevel1": "Acute toxic if you get small amounts",
    "dangerMouthLevel2": "May damage eventually if trace amounts come into the mouth via, for example, fingers.",
    "dangerMouthLevel3": "The substance is unsuitable to consume. (Applies to all hazardous substances.)",
    "dangerousOnSkin": "Harmful in contact with skin",
    "dangerousToEat": "Dangerous to consume",
    "dangerousToInHale": "Dangerous to inhale",
    "dangerToPutInMouth": "How dangerous are substances in the product to get into the mouth?",
    "database": "database",
    "dateOfRiskAssessment": "Risk assessment",
    "dateSDB": "Date SDS",
    "deactivateProduct": "Disable product",
    "declaredContent": "Declared content",
    "declaredContentItem1": "Enter the declared ingredients in the product. They are needed to go\n                                                                 further with risk assessment of the products. The constituent dangerous substances in the product\n                                                                 are listed in the safety data sheet under item 3. Composition.",
    "declaredContentItem2": "Enter the chemical substances declared on different lines.",
    "declaredContentItem3": "Enter the chemical identification number of the respective substance (CAS or EINECS (EU number))\n                                                                 which makes it easier to search information about the subject.",
    "declaredContentItem4": "If there are no chemical names or numbers, enter the type specified. description\n                                                                 of the type of substances. If nothing is specified enter \"undeclared\" or similar.",
    "declaredContentItem5": "Also indicate the H-phrases that the respective substance has been classified as dangerous for.",
    "declaredContentItem6": "How much does the product contain of the individual substance? This is usually stated in one\n                                                                 percent range. If the topics are prioritized or identified, it is important to get\n                                                                 an idea of the amount to make a risk assessment and choose the risk\n                                                                 valued and managed, and how to prioritize the risks you have.",
    "deleteChemicalInfo": "Do you really want to delete this link to the chemical?",
    "demandsEmergencyEquipment": "Requires emergency equipment",
    "demandsSafetyEquipment": "Requires <br/> protective equipment",
    "demandsSpecificWaste": "Requires specific <br/> waste disposal",
    "departmentConnectionMissing": "Link to a <br> department is missing",
    "departmentHeadLine": "Section",
    "departmentKemiInfo": "Enter the departments on which the chemical is located",
    "depGroup": "Department/Group",
    "description": "Description",
    "descriptionSupplier": "Description (Shared with the supplier)",
    "descriptionAccident": "Description (chain of events step-by-step)",

    "disableForThisCompany": "Disable for this company",
    "emergencyEquipInfo": "Emergency equipment <br/> required",
    "emergencyEquipment": "Emergency equipment ",
    "emergencyEquipmentText": "* Emergency equipment",
    "emptySection": "No department",
    "evaluationDateText": "Evaluation date",
    "evaluationResponsible": "Responsible for evaluation",
    "evaluationText": "Evaluation ",
    "explosive": "Explosive",
    "exportToExcel": "Export to Excel",
    "exportToPdf": "Export to Pdf",
    "fileAlreadyExists": "File already exists!",
    "fileTypeNotAllowed": "File type is not allowed!",
    "fileUploaded": "Uploaded file",
    "fillAPartInfoText": "Fill in the A-section below as per the safety data sheet.",
    "fillBPartInfoText": "Fill in the B section below. Save!",
    "fireAndExplosionRisks": "Flammability / explosion hazards",
    "fireDanger": "Fire hazardous",
    "fireDangerText": "flammable",
    "fireExplosionLevel1": "Very or extremely flammable (flash point < 23 \u00b0 C)",
    "fireExplosionLevel2": "Flammable (flash point> 23-60 \u00b0 C)",
    "fireExplosionLevel3": "Other classification regarding fire, reaction or explosion hazard",
    "gasUnderPreassure": "Gas under pressure",
    "gasUnderPreassureText": "Gas under pressure",
    "goodEnvironmentalChoice": "Good environmental choice!",
    "guidanceForDeclaredContent": "Guidance for declared content",
    "guidanceListItem1C": "For substances that should be noted, an assessment may need to be made if the substance is released\n                                                                     and if so, which is the main source of emissions. It is about this situation\n                                                                     A rough estimate, which is best done by the operator himself, which is necessary\n                                                                     knowledge of the conditions in the business. This is nothing needed to be done for\n                                                                     All subjects without focus should be on the topics that are included in any legislation in\n                                                                     primarily.",
    "guidanceListItem2C": "If the business manages a substance that is designated for example in water management\n                                                                     and this is used to a greater extent and there is a risk of\n                                                                     the substance may be released should indicate whether the discharge can go to a treatment plant or to\n                                                                     recipient.",
    "guidanceReciever": "Guidance recipient",
    "guidanceRisk": "Guidance risk assessment",
    "harmful": "Harmful.",
    "harmfulForSomeMaterials": "The substance may damage certain materials.",
    "hazardous": "Environmental hazard",
    "hazardousForEnvironMent": "Environment dangerous",
    "hazardousForEnvironmentText": "Environment dangerous",
    "hazardousGods": "Dangerous <br/> goods",
    "hazardousWaste": "Hazardous <br/> waste",
    "headerSection": "Chemicals / Section",
    "headlineText": "Heading",
    "healthAccusation": "Hazard statements for health hazards",
    "highRiskText": "High",
    "HphraseMeaning": "H- And P-Phrases CLP",
    "HPhrases": "H-Phrases",
    "HPhrasesDanger": "Hazard statements for physical hazards",
    "HPhrasesExplosive": "H201 Explosive",
    "HPhrasesFarao": "Faroese h-phrases",
    "HPhrasesH202": "H202 Explosive. Severe danger of splits and throws",
    "HPhrasesH203": "H203 Explosive. Danger of fire, pressure wave or splitter and throws",
    "HPhrasesH204": "H204 Risk of fire or splashes and throws",
    "HPhrasesH205": "H205 Risk of mass explosion in case of fire",
    "HPhrasesH220": "H220 Extremely flammable gas",
    "HPhrasesH221": "H221 Flammable gas",
    "HPhrasesH222": "H222 Extremely flammable aerosol",
    "HPhrasesH223": "H223 Flammable aerosol",
    "HPhrasesH224": "H224 Extremely flammable liquid and vapor",
    "HPhrasesH225": "H225 Highly flammable liquid and vapor",
    "HPhrasesH226": "H226 Flammable liquid and vapor",
    "HPhrasesH228": "H228 Flammable solid",
    "HPhrasesH240": "H240 Explosive when heated",
    "HPhrasesH241": "H241 Flammable or explosive on heating",
    "HPhrasesH242": "H242 Flammable when heated",
    "HPhrasesH250": "H250 Spontaneous teeth in contact with air",
    "HPhrasesH251": "H251 Self-heating. Can start burning.",
    "HPhrasesH252": "H252 Self-heating in large quantities. Can start burning.",
    "HPhrasesH260": "H260 In case of contact with water, flammable gases develop which can self-ignite",
    "HPhrasesH261": "H261 In case of contact with water, flammable gases develop",
    "HPhrasesH270": "H270 May cause or intensify fire. Oxidising.",
    "HPhrasesH271": "H271 May cause fire or explosion. Strong oxidizing.",
    "HPhrasesH272": "H272 Can intensify fire. Oxidising.",
    "HPhrasesH280": "H280 Contains gas under pressure. May explode when heated.",
    "HPhrasesH281": "H281 Contains refrigerated gas. May cause severe cold damage.",
    "HPhrasesH290": "H290 May be corrosive to metals",
    "HPhrasesH300": "H300 Harmful if swallowed",
    "HPhrasesH300H310": "H300 + H310 Harmful if swallowed or in contact with skin",
    "HPhrasesH300H330": "H300 + H330 Harmful if swallowed or inhaled",
    "HPhrasesH301": "H301 Toxic if swallowed",
    "HPhrasesH301H311": "H301 + H311 Toxic by inhalation or in contact with skin",
    "HPhrasesH301H311H331": "H301 + H311 + H331 Toxic if swallowed, skin contact or inhalation",
    "HPhrasesH301H331": "H301 + H331 Toxic if swallowed or inhaled",
    "HPhrasesH302": "H302 Harmful if swallowed",
    "HPhrasesH302H312": "H302 + H312 Harmful if swallowed or swallowed",
    "HPhrasesH302H312H332": "H302 + H312 + H332 Harmful if swallowed, in contact with skin or inhalation",
    "HPhrasesH302H332": "H302 + H332 Harmful if swallowed or inhaled",
    "HPhrasesH304": "H304 May be fatal if swallowed if it reaches the respiratory tract",
    "HPhrasesH310": "H310 Deadly in contact with skin",
    "HPhrasesH310H310H330": "H310 + H310 + H330 Harmful if swallowed, in contact with skin or inhalation",
    "HPhrasesH310H330": "H310 + H330 May cause skin contact or inhalation",
    "HPhrasesH311": "H311 Toxic in contact with skin",
    "HPhrasesH311H331": "H311 + H331 Toxic by skin contact or if swallowed",
    "HPhrasesH312": "H312 Harmful if swallowed",
    "HPhrasesH312H332": "H312 + H332 Harmful if swallowed or inhaled",
    "HPhrasesH314": "H314 Causes severe skin damage and eye damage",
    "HPhrasesH315": "H315 Irritating to skin",
    "HPhrasesH317": "H317 May cause allergic skin reactions",
    "HPhrasesH318": "H318 Causes serious eye damage",
    "HPhrasesH319": "H319 Causes severe eye irritation",
    "HPhrasesH330": "H330 Harmful by inhalation",
    "HPhrasesH331": "H331 Toxic by inhalation",
    "HPhrasesH332": "H332 Harmful by inhalation",
    "HPhrasesH334": "H334 May cause allergy or asthma symptoms or breathing difficulties when inhaled",
    "HPhrasesH335": "H335 May cause respiratory irritation",
    "HPhrasesH336": "H336 May cause drowsiness or drowsiness",
    "HPhrasesH340": "H340 May cause genetic defects",
    "HPhrasesH341": "H341 Suspected to cause genetic defects",
    "HPhrasesH350": "H350 May cause cancer",
    "HPhrasesH351": "H351 Suspected of causing cancer",
    "HPhrasesH360": "H360 May damage fertility or unborn child",
    "HPhrasesH361": "H361 Suspected to harm fertility or unborn child",
    "HPhrasesH362": "H362 May cause damage to breast-feeding babies",
    "HPhrasesH370": "H370 Causes organ damage",
    "HPhrasesH371": "H371 May cause organ damage",
    "HPhrasesH372": "H372 Causes organ damage by prolonged or repeated exposure",
    "HPhrasesH373": "H373 May cause organ damage by prolonged or repeated exposure",
    "HPhrasesH400": "H400 Very toxic to aquatic organisms",
    "HPhrasesH410": "H410 Very toxic to aquatic organisms with long-term effects",
    "HPhrasesH411": "H411 Toxic to aquatic organisms with long-term effects",
    "HPhrasesH412": "H412 Harmful long-term effects on aquatic organisms",
    "HPhrasesH413": "H413 May cause long-term adverse effects on aquatic organisms",
    "HPhrasesH420": "H420 Harms public health and the environment by destroying the ozone in the upper part of the atmosphere",
    "HPhrasesMassExplosion": "Go for mass explosion",
    "HPhrasesUnstable": "H200 Instable, explosive",
    "hygienicLimitValue": "Hygienic limit value",
    "infoTextTopicF": "Retrieve information from safety data sheets",
    "inSubjects": "A. B. Ingredients",
    "kemiActionHeadLine": "Action plan",
    "kemiActionShowClosed": "Also show closed action plans",
    "kemiActionSubText": "If the risk assessment has revealed that measures should be taken to eliminate or reduce the risk to the environment or health, an action plan must be created.",
    "kemiActive": "Active",
    "kemiAdminClassA": "Ingredients and classification of substances enl. CLP",
    "kemiAdminClassB": "Should the following topics be considered?",
    "kemiAdminClassC": "Where do subjects go the way? Main recipient",
    "kemiAdminClassD": "Annual consumption",
    "kemiAdminClassDInformation": "Enter year, amount, and device. The graph is updated if you close the tabs and open\n                                                     again.",
    "kemiAdminClassE": "Safety Data Sheet",
    "kemiAdminClassF": "Pictogram according to CLP",
    "kemiAdminClassG": "Risk assessment",
    "kemiAdminClassH": "Action plan",
    "kemiAdminClassI": "Which department?",
    "kemiADminClassJ": "Printing",
    "kemiAdminHeadLine": "Admin. chemicals.",
    "kemiAdminInfo": "Record all chemical substances and products that have an environmental impact that you use\n                                 in the business. The list shall contain all products that are subject to labeling\n                                 because the product is classified as dangerous or contains dangerous substances\n                                 substances above a certain level (all products have been given a safety data sheet for!).",
    "kemiAdminInfoInstructions": "To fill in A-H, click the (>) - sign in front of the \"Change\" button (at the top of the row)!",
    "kemiAdminProdClass": "Product and product classification enl. CLP",
    "kemiAdminSafetySheet": "Fill in information according to the Safety Data Sheet",
    "kemiCandidateList": "Candidate <br> list",
    "kemiCap14": "Reach Chap. 14",
    "kemiCap17": "Reach Chap. 17",
    "kemiCasNr": "CasNr",
    "kemiControledDate": "Controlled",
    "kemiCustomerDemands": "Customer requirements.",
    "kemiDangerClasses": "hazard classes",
    "kemiDangerDeviation": "Hazard statements for environmental hazards",
    "kemiDemandsComments": "Comment Customer Requirements.",
    "kemiFraser": "H Fraser",
    "kemiHeaderText": "Ingredients.",
    "kemiLimitDB": "Restriction- <br/> database",
    "kemiMenuItem1": "Admin. Chemicals",
    "kemiMenuItem2": "Admin. Chemicals / Section",
    "kemiMenuItem3": "Inventory (list)",
    "kemiMenuItem4": "Help",
    "kemiNoSubstanceRegistered": "No incoming subjects registered",
    "kemiPartOfProduct": "Included in the product",
    "kemiPrio": "Prio",
    "kemiPrioHeadLine": "PRIO (U, rR): <br/> Phase or Risk Reduction Substance",
    "kemiShowInactiveProducts": "Also show inactive products",
    "kemiSubstance": "Subject.",
    "kemiSupplier": "Supplier",
    "kemiUse": "Use.",
    "kemiWater": "Water <br/> management",
    "kindOfDanger": "Kind of danger",
    "lastControlledBy": "Lastly checked it",
    "lastUpdatedBy": "Last updated by",
    "levelOfFireAndExplosion": "How flammable or explosive are substances in the product?",
    "limit": "Limit",
    "limitation": "Limitation-",
    "limitations": "- Limitations",
    "limitDB": "Restriction database",
    "limitDbShort": "Restr. <br> DB.",
    "linkMissing": "Link missin",
    "linkText": "Link",
    "linkToSafety": "Linked Safety Data Sheet",
    "linkToSafetySheet": "Link to safety sheet",
    "listItemG1": "The basic thing is that you start by getting an idea of the risks\n                                                                 the hazardous chemical substances present so that you understand what situations they are\n                                                                 causes damage. If the information from the supplier (usually the safety data sheet) is not\n                                                                 Given this understanding, it may be necessary to speak with the person responsible for the information\n                                                                 and request clarifications.",
    "listItemG2": "It is important to clarify the boundaries of the risk assessment you intend to do,\n                                                                 ie what types of risks are involved and what work or premises as\n                                                                 included.",
    "listItemG3": "In risk assessment, the risk of inhalation, skin contact, ingestion, fire-\n                                                                 and explosion hazard and other hazardous properties separately. Sometimes the same action can be taken\n                                                                 affect multiple risks. In order to finally take a stand for the need for action needs\n                                                                 you look at the whole.",
    "listItemToInvestigate1": "The substance may be regulated by the Danish Chemicals Agency's legislation with restrictions\n                                                             in the use according to the restriction database? If the subject is limited then revise\n                                                             Your use is legal.",
    "listItemToInvestigate2": "Has the substance properties that make it phasing out or is a priority risk reduction substance\n                                                             according to the PRIO database?",
    "listItemToInvestigate3": "Note with U for phase-out substance and R for risk reduction substance. You can search\n                                                             topics in the sample database, but it is not recommended to do this only. For\n                                                             To be sure if the subject belongs to categories U or R, it should be compared to the properties\n                                                             under the \"criteria\" of the prioguide.",
    "listItemToInvestigate4": "Is the substance occupied as a priority substance according to Annex 10 of the EU Water Directive?\n                                                             The subjects are designated within the EU and have EU common guideline values. What topics and topics\n                                                             that is, can be found in the daughter directive on priority topics:",
    "listItemToInvestigate5": "Particular pollutants in water management are substances that affect it\n                                                             ecological status. The subjects are selected because they are judged in Sweden\n                                                             hazardous to the aquatic environment and released in significant amounts. The topics that you have so far\n                                                             Picked up guideline values can be found in the following report:",
    "listItemToInvestigate6": "Particularly dangerous substances that are listed on the Candidate List for REACH licenses.\n                                                             These substances shall, for example, be declared in goods where the content exceeds 0,1%.",
    "listItemToInvestigate7": "Check if the substances are listed in Reach Annex 14 (permission for particular\n                                                             hazardous chemicals),",
    "listItemToInvestigate7LastLine": "or Annex 17 (limiting substances)",
    "listItemToInvestigate8": "Under each link you can also read more.",
    "listOfCandidate": "The candidate / list",
    "listOfCandidates": "Candidate list",
    "listsToInvestigate": "List to scan",
    "loadingText": "Loading...",
    "locationOfBusiness": "Location of business",
    "locationOfBusinessKemiInfo": "List the places of operation where the chemical is located",
    "locationStorageOccurrence": "Location storage/Occurrence",
    "lookThroughSubjects": "Review the topic of the topic in the different products and note:",
    "lowRiskText": "Low",
    "mainReciever": "Main recipient",
    "mandatoryFields": "(Required fields",
    "manufacturer": "Manufacturer",
    "measurement": "Measurement",
    "noChemicals": "No chemicals",
    "noDeclaredSubjects": "No declared substances",
    "noRegisteredInfo": "No registered",
    "noText": "No",
    "notifyInfoText": "Do you really want to send reminder to the manager?",
    "notifyResponsible": "Remind ansv.",
    "notSubjectToLabeling": "Not subject to labeling",
    "notSubjectToLabelingLong": "Not subject to labeling according to CLP (EC) No 1272/2008",
    "notSupportedFileType": "This file type is not approved here!",
    "noUploadedFileText": "No uploaded files",
    "openBtnText": "Open",
    "other": "Other",
    "otherDanger": "Other danger",
    "otherDangerChar": "Other hazardous properties",
    "otherDangerLevel1": "May be absorbed through the skin and damage to other organs.",
    "otherDangerLevel2": "The skin is damaged directly (usually because it is highly corrosive).",
    "otherDangerLevel3": "Skin damage occurs if the substance may remain on the skin.",
    "otherDangerLevel4": "Permanent damage (such as cancer or allergy).",
    "otherDangerLevel5": "Transient damage (heals if exposure expires).",
    "otherDangerProperties": "Other hazardous properties?",
    "otherInfoAboutFaror": "Are there any other hazard information about substances in the product?",
    "oxid": "Oxidising",
    "personalPPE": "Personal Protective <br> Equipment",
    "personalPPE1": "Respiratory protection",
    "personalPPE2": "Face <br/> protection",
    "personalPPE3": "Protective <br/> glasses",
    "personalPPE4": "Protective <br/> gloves",
    "personalPPE5": "Protective <br/> clothes",
    "personalPPE6": "Protective <br/> shoes",
    "personalPpeText": "* Personal protective equipment",
    "piktogramCaption": "Pictogram",
    "possibleSafeGuards": "Possible safeguards",
    "printedDate": "Printed date",
    "printHeadLine": "Print",
    "prioDatabase": "PRIO-database",
    "prioDB": "PRIO-database <br/> (U,R)",
    "prioDbBr": "PRIO- <br/> database",
    "procentInSubject": "Share (%) <br/> in the product",
    "procentOfSubjectInProduct": "Share (%) <br/> of substance in the product",
    "product": "Product",
    "productNameText": "Productname",
    "reachBil14": "Reach/ <br/> bil. 14",
    "reachBil14PermitDemands": "Reach car. 14 <br/> - Condition Requirements",
    "reachBil17": "Reach/ <br/> bil. 17",
    "reachBil17Limits": "Reach bil. 17 <br/> - Limits",
    "rectifiedByTheLatest": "Addressed. last",
    "responsibleText": "Responsible",
    "risk": "Risk",
    "riskAnalysis": "Risk Assessment",
    "riskAssessmentDate": "Date of <br/> Risk Assessment",
    "riskFireExplosion": "Fire / Explosion",
    "riskFor": "Risk for",
    "riskForExplosionFire": "Flammable-explosion",
    "riskInhale": "Inhalation",
    "riskOther": "Other danger",
    "riskSkinExposure": "Skin",
    "riskSwallows": "Ingestion",
    "safetyEquipment": "Shielding equipment",
    "safetySheet": "Safety Data Sheet",
    "safetySheetDateText": "Date of Safety Data Sheet:",
    "saveCustomer": "NOTE! Save customer first. Upload file later.",
    "searchNullText": "Search phrase...",
    "showAll": "Show all",
    "showChemicalsForSpecDepartment": "Show chemicals for specific department",
    "showChemicalsForSpecLocations": "and/or for specific localtion of business:",
    "specialRules": "Special rules",
    "specificDisposalText": "Specific waste handling",
    "specificWaste": "Specific waste management",
    "specificWasteDisposal": "Specific waste <br/> management",
    "stateClaim": "- State claims",
    "statusText": "Status",
    "subjectContactWithOther": "The substance may react violently with another substance or under certain conditions (such as heat, radiation).",
    "subjectInAir": "How easily do substances in the product get into the air?",
    "substancesFormed": "Substances<br/>formed",
    "subTextA": "Ingredients and classification of substances enl. CLP",
    "subTextB": "B. Upload claim documents or link to customer requirements",
    "textTypeAnswer": "Enter answers",
    "toBeDoneAtTheLatest": "Lastly, it will be rectified",
    "topicTextA": "A. Declared content",
    "topicTextB": "B. Should the subject be noted?",
    "topicTextC": "C. Main recipient",
    "topicTextD": "D. Consumption",
    "topicTextE": "Upload safety data sheet or link to supplier",
    "topicTextE2": "E. Safety data sheet",
    "topicTextF": "F. Pictogram CLP",
    "topicTextG": "G. Risk assessment",
    "topicTextGInfo": "All labeling chemicals and chemical products shall be assessed for risk.",
    "topicTextH": "H. Action",
    "topicTextI": "I. Section",
    "topicTextJ": "J. Print",
    "totalRisk": "Risk",
    "totalRiskAssessment": "Total risk assessment",
    "Hög": "High",
    "Måttlig": "Medium",
    "Låg": "Low",
    "toxic": "Posion",
    "typeHeadLineInfoText": "Enter heading!",
    "typeResponsibleText": "Enter the responsible!",
    "unhealthy": "Health dangerous",
    "unhealthyText": "UNHEALTHY",
    "uploadBtnText": "Upload file",
    "uploadClaimDoc": "Upload claim document",
    "uploadedClaimDoc": "Uploaded claim document",
    "uploadedFileInformationText": "Uploaded file will not be visible until you close the dialog!",
    "uploadedFiles": "Uploaded files",
    "uploadFiles": "Upload file/files",
    "uploadFileText": "Upload file",
    "use": "Use",
    "usedFor": "Used for",
    "wasteDisposal": "Waste disposal",
    "water": "Water-",
    "waterCleanser": "Water <br/> - Cleaning plants",
    "waterManagement": "Water / Administration",
    "waterManagementHeadLine": "Water management <br> (S, P)",
    "waterManagementHeadLine2": "Water Management (S, P): <br> Particularly Contaminated or Priority",
    "waterReciever": "Water / <br/> -Recipient",
    "whereDoSubjectsGo": "Where do subjects go the way?",
    "yesText": "Yes",
    "riskAssesmentDate": "Assesment performed",
    "dateFrom": "Date from",
    "dateTo": "Date to",
    "dateFilter": "Filter by date",
    "filter": "Filter",
    "resetFilters": "Reset search/grouping/sorting/filtering",
    "addNew": "Add new",
    "connectPosition": "Connect competence requirements to positions",

    "addValue": "Add new {{value}}",
    "addMultiValue": "Add one or more {{value}}",
    "editValue": "Edit {{value}}",
    "editValueOwnId": "Edit {{value}} id: {{ownId}}",
    "topic": "Title",
    "totalValue": "Total: {{value}}",
    "department": "Department/Group",
    "responsible": "Responsible",
    "responsibleId": "Responsible",
    "safetyOfficerId": "Safety representative",
    "reporterId": "Reported by",
    "reportedBy": "Reported by",
    "reportedAt": "Reported at",
    "remindResponsible": "Remind responsible",
    "lawRequirement": "Laws / Requirements",
    "status": "Status",
    "activity": "Activity",
    "cost": "Cost (kr)",
    "time": "Time (h)",
    "doneBefore": "Done before",
    "doneBy": "Done by",
    "doneDate": "Date done",
    'Summarize content': 'Summarize content',
    'Improve writing': 'Improve writing',
    'Simplify language': 'Simplify language',
    'Expand upon': 'Expand upon',
    'Trim content': 'Trim content',
    'Change tone': 'Change tone',
    'Change style': 'Change style',
    'Translate': 'Translate',
    'Professional': 'Professional',
    'Casual': 'Casual',
    'Direct': 'Direct',
    'Business': 'Business',
    'Legal': 'Legal',
    'Translate to English': 'Translate to English',
    'Translate to Spanish': 'Translate to Spanish',
    'Translate to German': 'Translate to German',
    'Translate to French': 'Translate to French',
    'Translate to Norwegian': 'Translate to Norwegian',
    'Translate to Polish': 'Translate to Polish',
    "filename": "Filename",
    "fileType": "Type",
    "deleteFile": "Delete file",
    "endedBy": "Closed by",
    "endedDate": "Closed at",
    "reviewResponsibleId": "Responsible for evaluation",
    "reviewDate": "Evaluated at",

    "active": "Active",
    "activeSupplier": "Active supplier",

    "connection": "Connection",
    "closed": "Closed",
    "showClosed": "Show closed",
    "showSettings": "Show settings",

    "type": "Type",
    "lawUpdatedTo": "Updated to version",
    "affectedBy": "What in the law that affects us",
    "link": "Link",
    "signout": "Sign out",
    "company": "Company",
    "send": "Send",
    "sent": "Sent",
    "sendReminder": "Send reminder",
    "mailHasBeenSend": "Your email has been sent",
    "template": "Template",
    "apply": "Apply",
    "done": "Done",
    "edit": "Edit",
    "extraFields": "Extra fields",
    "extraFieldsValue": "Extra fields {{value}}",
    "textLinkReporting": "Text/Link reporting",
    "responsible&safety": "Responsible/Safety representative",
    "safetyOfficer": "Safety representative",
    "deviationResponsible": "Deviation responsible",
    "accidentResponsible": "Accident/Incident/Risk observation responsible",

    "required": "Required",
    "invalidUrl": "Invalid url",
    "noDecimals": "No decimals allowed",
    "noNegative": "No negative values allowed",
    "keyMustBeUnique": "Field names must be unique",
    "yes": "Yes",
    "no": "No",
    "or": "Or",
    "name": "Name",
    "settings": "Settings",
    "visibleColumns": "Visible columns",
    "delete": "Delete",
    "deleteConnectedPosition": "Delete connection to position",

    "showInactive": "Show inactive",
    "inactive": "Inactive",
    "dropToGroupBy": "Drop here to group by column",
    "dropFileHere": "Drop file here to add",
    "areYouSure": "Are you sure?",
    "viewMore": "View more",
    "review": "Evaluate",
    "okey": "Okey",
    "loading": "Loading",
    "adaptHeightToContent": "Adapt height to content",
    "lastUpdated": "Last updated",
    "performedBy": "Performed by",
    "customer": "Customer",
    "evaluation": "Evaluation",
    "task": "Task",
    "risksOppertunity": "Risk",
    "process": "Process",
    "impact": "Impact",
    "worth": "Worth",
    "approveSuggestion": "Approve suggestion",
    "denySuggestion": "Deny suggestion",
    "changedBy": "Suggested by",
    "changedByAndDate": "Suggestion sent on {{date}} by {{name}} ",
    "reviewFor": "Review suggestion by {{name}}",
    "current": "Current",
    "changesSaved": "Changes have been saved",
    "concern": "Concern",
    "latest": "Latest",
    "user": "User",
    "users": "Users",
    "open": "Open",
    "add": "Add",
    "acknowledged": "Aknowledged",
    "hidden": "Hidden",
    "sorting": "Sorting",
    "copy": "Copy",
    "language": "Language",
    "swedish": "Swedish",
    "english": "English",
    "import": "Import",
    "workLocation": "Location",
    "issue": "Deviation",

    "startDate": "Start date",
    "endDate": "End date",
    "saved": "Saved",
    "notificationsSend": "Notifications send:",
    "of": "of",
    "standard": "Standard",
    "module": "Module",
    "created": "Created",
    "deleted": "Deleted",
    "restore": "Restore",
    "userFromAnotherCompany": "User from another company",
    "routine": "Routine",
    "routines": "Routines",
    "version": "Version",
    "concernDocuments": "Group routines",
    "documents": "Documents",
    "archiveSite": "Archive location",
    "archiveTime": "Archive time",
    "risksOppertunities": "Risks / Opportunities",
    "responsiblePosition": "Responsible position",
    "responsiblePositionConcern": "Responsible position (Group)",
    "configure responsible": "Configure responsible position",
    "review suggestion": "Review suggestion",
    "submit suggestion": "Submit suggestion",
    "documents stored in other location": "Documents stored in another location",
    "sendForApproval": "Send for approval",
    "sendTo": "Send to",
    "hasBeenSend": "The suggested change is now sent for review",
    "commentYourChanges": "Comment your suggested changes",
    "confirmDocumentRead": "An administrator wishes to recieve confirmation that you have read this routine. Please use the button to the right to do so.",
    "markAsViewed": "Mark as viewed",
    "activityProcessService": "Activity/Process/Service",
    "thisIsACopyCheckOriginal": "This is a copy. Check printing on originals in VIQMA System!",
    "routineIsOnRemiss": "Routine on referral",
    "compareAndDisplayVersions": "Compare and display versions",
    "changesLatestVersions": "Changes compared with latest Version",
    "showChangesLatestVersions": " Show changed compared with latest version",
    "compareFrom": "Compare from",
    "compareTo": "Compare to",
    "addExternalDocument": "Add document stored in another location",
    "directApproval": "Approve suggestion directly",
    "documentBelongsTo": "Routine from: {{name}}",
    "selectReadConfirmationCase": "Select a case to the left in order to see it here",
    "skillPositions": "Positions",
    "createReadConfirmationCase": "Create new receipt task",
    "addResponsiblePositions": "Add additional positions",
    "archiveReportedDocs": "Record",
    "sortOrder": "Sort order (default alphabetically)",
    "adminDocument": "Admin routine",
    "dropHereToMoveToRoot": "Drop here to move to root",
    "governingProcess": "Governing process",
    "mainProcess": "Main process",
    "supportProcess": "Support process",
    "updatedTemplateRoutinesInLast12Months": "Updated VIQMA template routines (within last 12 months)",
    "notSharedToSubCompanies": "Not shown on devices / underlying companies",
    "saveAsVersion1": "Save as version 1 and delete all previous versions",
    "sureYouWantToDeleteDocument": "Do you really want to delete the routine and all the linked files? That means everybody. uploaded files, accounting documents. in the archive table, earlier versions of the routine and links to laws.",
    "cantDeleteRoutineWithChildren": "Can't delete. The routine has underlying routines",
    "hidingDocumentWillAffectChildren": "Hiding this routine will also hide all children",
    "useButtonToImportRoutines": "Use the button to the right to import one or more routines from template companies.",
    "copyFunction": "Copy from other routine",
    "suggestedLawConnections": "Suggested law connections",
    "addLawConnection": "Add connection to law",
    "editorIsReadOnlyClickToActivate": "Editor is currently in read only mode, click to activate and allow changes",
    "error": "Error",
    "notAllowedToApproveDocument": "You are not allowed to approve this document",
    "documentBelongToAnotherCompany": "Document belong to another company",
    "goalsHeader": "Goals",
    "addDecision": "Add Monitoring / Decisions",
    "updateDecision": "Update Monitoring / Decisions",
    "occasion": "Occasion",
    "decisions": "Monitoring / Decisions",
    "decision": "Monitoring / Decisions",
    "connectedMeasurement": "Connected measurement",
    "start": "Start",
    "targetValue": "Target value",
    "addActivity": "Add new activity",
    "updateActivity": "Update activity",
    "numDecisions": "Number of decisions",
    "numActivities": "Number of active activities",
    "startValue": "Start value",
    "currentValue": "Current value",
    "chooseExistingOrCreate": "Choose an existing measurement or create",
    "newMeasurement": "New measurement",
    "newMeasurementValue": "Add new sample",
    "detailedType": "Detailed type",
    "unit": "Unit",
    "firstMeasurement": "First sample",
    "period": "Period",
    "yearly": "Yearly",
    "monthly": "Monthly",
    "weekly": "Weekly",
    "week": "Week",
    "month": "Month",
    "value": "Value",
    "newSubGoal": "New sub goal",
    "kpi": "KPI",
    "sdg": "Sustainable development goals",
    "sdg.tooltip": "Clarify your company's sustainability efforts by linking your goals to one or more of the 17 overarching Sustainable Development Goals agreed by UN member states.",
    "sdg.1": "No Poverty",
    "sdg.2": "Zero Hunger",
    "sdg.3": "Good Health and Well-being",
    "sdg.4": "Quality Education",
    "sdg.5": "Gender Equality",
    "sdg.6": "Clean Water and Sanitation",
    "sdg.7": "Affordable and Clean Energy",
    "sdg.8": "Decent Work and Economic Growth",
    "sdg.9": "Industry, Innovation and Infrastructure",
    "sdg.10": "Reduced Inequality",
    "sdg.11": "Sustainable Cities and Communities",
    "sdg.12": "Responsible Consumption and Production",
    "sdg.13": "Climate Action",
    "sdg.14": "Life Below Water",
    "sdg.15": "Life on Land",
    "sdg.16": "Peace and Justice Strong Institutions",
    "sdg.17": "Partnerships to Achieve the Goal",

    "restructureGoals": "Restructure goals",
    "restructure": "Restructure",
    "restructureGoalsInfoText": "Here you can restructure how goals fit together. A sub goal can be moved to another main goal or be pulled to the root making it a new main goal. Note that a main goal that has sub goals can't be moved.",
    "forbiddenOperationHasChildren": "This operation is not allowed, goal has children",
    "forbiddenOperationIsAChild": "This operation is not allowed, goal is a sub goal",
    "forbiddenOperationSelfParentingNotAllowed": "This operation is not allowed, goal can't be its own parent",
    "cantCloseGoalIfThereAreOpenChildren": "To close a main goal you must first close all sub goals or use the restructure functionality",
    "useFirstValue": "Enter first measurement value",
    "measurementValues": "Measurement values",
    "editMeasurement": "Edit measurement",
    "cantDeleteGoalWithChildren": "Can't delete. The goal has sub goals",
    "sureYouWantToDeleteGoal": "Are you sure you want to delete the goal?",
    "alsoShowClosed": "Show closed as well",
    "displayDataAs": "Display data as",
    "lineDiagram": "Line chart",
    "barDiagram": "Bar chart",
    "stackedBarDiagram": "Stacked bar chart",
    "pieDiagram": "Pie chart",
    "goal": "Goal",
    "idea": "Idea",
    "expectedResult": "Expected result",
    "suggestion": "Solution proposal",
    "groupId": "Group",
    "issueType": "Deviation type",
    "errorCode": "Error code",
    "sendEmailToResponsible": "Send email to responsible",
    "sendEmailToSafetyOfficer": "Send email to safety representative",
    "sendDeviationManagementEmail": "Send email with link to deviation management",
    "sendDeviationManagementEmailChecked": "If checked, an email message will be sent with a link to a form when you press save. The form contains information about registered deviations, which must be completed by the supplier regarding cause analysis and corrective action.",
    "specialProject": "Special project",
    "causeCode": "Cause code",
    "orderNumber": "Ordernumber",
    "isReported": "Reported",
    "incidentIsReported": "Incident reported (to be filled in by responsible)",
    "accidentDate": "Accident date",
    "accidentTime": "Time",
    "optionalNameOfTheInjuredPerson": "Name of the injured person: (Optional)",
    "employmentType": "Type of employment",
    "employmentYear": "Employment period (numer of years)",
    "whatHappened": "Cause of accident",
    "factor": "Factor",
    "differencesFromNormal": "What was temporarily different than it usually is? Why did it happen?",
    "conditionalEffects": "Note",
    "locationHadImpact": "E.g. lighting, visibility, heat, cold, noise, working positions, the condition of the surface, slippage, cleaning, order",
    "equipmentHadImpact": "E.g. unprotected machine movements, worn equipment, wrong tools, missing mobility aids, use of personal protective equipment, unsuitable shoes/clothes/gloves",
    "workEnvironmentHadImpact": "E.g. constant time pressure, production planning, piecework salary, overtime, routine work, no buffers, problems with absence, cooperation problems",
    "preventiveActionsHadImpact": "E.g. the risk could have been discovered and eliminated during risk inventory, safety rounds, maintenance",
    "companyClimateHadImpact": "E.g. production safety conflict, work/safety instructions, routines for the work are missing, knowledge of incidents or deviations has not been reported or remedied",
    "educationHadImpact": "E.g. person lacked competence, introduction, internal training, workplace meetings, information had not reached the employee or manager, or routines and instructions were not followed",
    "otherCausesHadImpact": "Other conditions, note what",
    "estimatedAbsenceTime": "Probable sick leave",
    "whereDidTheIncidentOccur": "Where did the incident occur? (In building, machine, vehicle etc.)",
    "whatWasTheActualTaskWhenTheIncidentOccured": "What was the current work operation when the incident occurred?",
    "ifPersonalInjuryWhatCausedIt": "In case of personal injury: What did the person injure?",

    "couldHaveHadeAffect": "Relationships that were as they usually but may have affected the even",
    "checkAllThatApply": "Check all that apply",
    "locationWhereItHappened": "The place where it happened",
    "equipmentAndClothes": "Equipment, clothing",
    "workSituation": "The work situation",
    "preventiveActions": "Preventive work",
    "securityClimate": "Safety climate",
    "educationAndInformation": "Education and information",
    "otherWriteBelow": "Otherwise, write what",
    "incident": "Incident",
    "riskObservation": "Observed risk",
    "heading": "Title",

    "projects": "Project",
    "risks": "Risk/Opportunity",
    "goals": "Goals/Measurements",
    "laws": "Laws/Requirements",
    "suppliers": "Suppliers",
    "adminSuppliers": "Admin. Suppliers",

    "surveys": "Surveys",
    "statistics": "Statistics",
    "valueStatistics": "{{value}} statistics",

    "perform": "Perform",
    "handleCase": "Handle task",
    "priority": "Priority",
    "estimatedTime": "Estimated time",
    "noCases": "You have no tasks to handle",
    "actualTime": "Real time",
    "materialCost": "Material cost",
    "laborCost": "Labor cost",
    "externalActor": "External performer",
    "externalActorTime": "Time external service",
    "externalActorCostPerHour": "Cost/h external service",
    "high": "High",
    "medium": "Medium",
    "low": "Low",
    "sendMailToHandler": "Send email to person doing the work",
    "case": "Task",
    "measurementDevice": "Measurement device",
    "result": "Action/Result",
    "instruction": "Instruction/Assignment",
    "projectStart": "Project start",
    "projectEnd": "Project end",
    "projectDescription": "Description (background/now)",
    "projectGoal": "Project goal",
    "project": "Project",
    "projectActivity": "Project activity",
    "projectPlan": "Project plan",
    "implementation": "Implementation",
    "endProject": "End project",
    "sendAsAppointment": "Send as appointment to my calender in outlook",
    "sendMailToResponsible": "Send email to responsible",
    "useProjectTemplate": "Use project template",
    "projectTemplateCanDo": "A template can add custom fields an prepopulate your project with standard activities",
    "templateApplied": "Template was applied",
    "projectTemplateAdminIntroText": "Here you can create new project template or edit existing. To edit pick one in the table and to create a new use the button marked \"Add new\".",
    "fieldName": "Field name",
    /* eslint-disable no-useless-escape */
    "fieldNameNoChar": `Field name, invalid special characters (."'[]\:/;,&_)`,
    "fieldNameError": `The field name contains invalid special characters`,
    "usernameError": `Name is invalid, can only contain letters or digits.`,


    "fieldType": "Field type",
    "fieldType.0": "Text",
    "fieldType.4": "Multi user",
    "number": "Number",
    "string": "Text",
    "date": "Date",
    "approvedBy": "Approved by",
    "userDropdown": "User",
    "multipleUserDropdown": "Multi user",
    "useActivitiesAsChecklist": "Use activities as checklist",
    "projectList": "Project list",
    "projectTemplate": "Project template",
    "projectTemplateList": "Project template list",
    "adaptHeightToWindow": "Adjust height to window",
    "searchPage": "Search page",
    "searchPhrase": "Searchphrase",
    "filterResponsiblePosition": "Filter on responsible position",
    "noSearchHits": "Search returned no result",
    "sharedRoutines": "Shared routines",
    "sharedUploadedFiles": "Shared uploaded files",
    "accidentUploadedFiles": "Uploaded files accident/incident/risk observation",
    "certexUploadedFiles": "Uploaded files certex",
    "projectFiles": "Project files",
    "nordProFiles": "Project files Nordshakt",
    "osterlingsProFiles": "Project files Österlings",
    "viqmaProFiles": "Viqma prospect files",
    "specProFiles": "Special project files",
    "objectFiles": "Object files",
    "objectCaseFiles": "Object case files",
    "objectRiskFiles": "Object risk files",
    "objectRiskActionPlanFiles": "Object risk action plan files",
    "calibrationFiles": "Calibration files",
    "accidentIncidentRisk": "Accident/Incident/Risk observation",
    "goalUploadedFiles": "Goal files",
    "skillsUploadedFiles": "Competence files",
    "issueUploadedFiles": "Deviations uploaded files",
    "externalUploadedFiles": "Supplier – External Uploaded Files",
    "internalUploadedFiles": "Supplier – Internal Uploaded Files",
    "riskUploadedFiles": "Risk files",
    "riskHplFiles": "Risk action plan files",

    "chemicalsUploadedFiles": "Chemicals - Uploaded files",
    "improvementsUploadedFiles": "Improvements - Uploaded Files",
    "systematicTasksUploadedFiles": "Systematic task - Uploaded Files",
    "documentsUploadedFiles": "Routines - Uploaded Files",
    "sharedDocumentsUploadedFiles": "Shared routines - Uploaded Files",

    "plannedCompletedEducations": "Planned/performed educations",
    "pendingDocumentReadConfirmations": "Routines on referral",
    "documentsUpdatedThisMonth": "Routines updated during last month",
    "deviations": "Deviations",
    "deviation": "Deviation",
    "deviationsCertex": "Deviations",
    "deviationsSkog": "Deviations",
    "educationLiftetime": "Competence - educations validity",
    "skill": "Competence",
    "goalActivities": "Goal activities",
    "updatedLaws": "Updated Laws",
    "invitedParticpants": "Invited as participants to the systematic task",
    "particpants": "Participants",
    "teamMemberId": "Team member",
    "deviationTeamMembers": "Invited as team member to the deviation",
    "outcome": "Outcome",
    "issueReview": "Evaluation of Deviations",
    "activities": "Activities",
    "emergency": "Emergency",
    "newSuppliers": "New suppliers",
    "supplierActions": "Suppliers - Measures",
    "supplierActionsApi": "Suppliers - Measures",
    "supplierCertificate": "Suppliers - certificate valid until",
    "supplierCertificateApi": "Suppliers - certificate valid until",
    "supplierNotAnswered": "Suppliers - unresponsive to questions",
    "supplierNotAnsweredApi": "Suppliers - unresponsive to questions",
    "supplierEvaluated": "Supplier - Internal evaluated",
    "supplierEvaluatedApi": "Supplier - Internal evaluated",
    "supplieDeliverStatistic": "Suppliers - Delivery Statistics",
    "tasks": "Tasks",
    "objectTasks": "Tasks",
    "objekt": "Object",
    "category": "Categories",
    "riskGoalsPlanes": "Risk assessment - Action Plans",
    "evaluationRiskPlanes": "Risk Assessment - Evaluation of Action Plans",
    "year": "Year",
    "updatedDesignation": "Current upd.",
    "supplier": "Supplier",
    "certKv": "Q-cert",
    "certMi": "M-cert",
    "certAr": "A-cert",
    "verCSR": "CSR-ver",
    "certOv": "Other cert",
    "iso3834": "3834-cert",
    "iso1090": "1090-cert",
    "noNews": "There are no active news at the moment",
    "ownId": "Id",
    "reportedDate": "Reported at",
    "acknowledgeReadingDocuments": "Acknowledge reading routine",
    "deviationActivities": "Deviation activities",
    "deviationActivitiesSkog": "Deviation activities",
    "deviationActivitiesCertex": "Deviation activities",
    "evaluationDeviations": "Evluation of deviation",
    "evaluationDeviationsSkog": "Evluation of deviation",
    "evaluationDeviationsCertex": "Evluation of deviation",
    "areYouSureYouWishToSendReminder": "Are you sure you wish to send a reminder?",
    "all": "All",
    "visible": "Visible",
    "denomination": "Denomination",
    "designation": "Designation",
    "applicableFrom": "Applicable from",
    "dateSent": "Date sent",
    "systematicTasksIssues": "Deviation in systematic task",
    "removeConnection": "Remove connection",
    "viqmaProjectActivity": "Customer project",
    "accidentIncident": "Accident/Incident/Risk observation",
    "accidentSafetyOfficer": "Accident/Incident/Risk observation - Safety Representative",
    "calibrationWaiting": "NOTE! There are tools that need to be calibrated!",
    "courseReviewWaiting": "NOTE! You have a completed training for evaluation.",
    "goCompetence": "Click here to go to Competence/Education and evaluation!",
    "goCalibration": "Click here to go to the calibration!",
    "deviationsPerYear": "Deviations/Year",
    "deviationsPerMonth": "Deviations/Month",
    "effiencyPerMonth": "Effiency/Month",
    "averageDaysUntilResolve": "Average number of days until solution",
    "accidentsPerYear": "Accidents/Year",
    "issueCostPerMonth": "Issue Cost/Month",
    "improvementIdeasPerYear": "Improvement Ideas/Year",
    "improvementIdeasPerMonth": "Improvement Ideas/Month",
    "improvementsPerYear": "Improvement Activites/Year",
    "improvementsPerMonth": "Improvement Activites/Month",
    "improvementEffecieny": "Effeciency of improvment implementation",
    "riskActionPlans": "Risk / Opportunity - Action Plans",
    "accidentActivities": "Accident/Incident/Risk observation - Activities",
    "filesAddedThisMonth": "Files added this month",
    "concernFilesAddedThisMonth": "Group files added this month",
    "routineRemiss": "Routines on review",
    "activityTools": "Activities measurement tools",
    "evaluationAccidents": "Evaluation of Risk/Accident",
    "nordProjectActivity": "Project activities (Shaft)",
    "ostProjectActivity": "Project activities (Construction)",
    "specProjectActivity": "Special project",
    "chemicalsActions": "Action plans for chemicals",
    "activityImprovments": "Improvement activities",
    "ideasImprovments": "Improvement ideas",
    "improvementIdea": "Improvement idea",
    "improvementIdeas": "Improvement ideas",
    "improvementActivity": "Improvement activity",
    "improvementActivities": "Improvement activities",
    "improvementGroups": "Improvement Groups",
    "group": "Group",
    "todo": "To do",
    "concernDocumentsUpdatedThisMonth": "Group routines updated last month",
    "reviewBefore": "Review before",
    "updatedTemplateDocuments": "Updated VIQMA Template routines (last 3 months)",
    "updatedTemplateFiles": "Updated VIQMA Template Files",
    "belongToRoutine": "Belong to routine",
    "standby": "Pending decisions",
    "decisionMade": "Decision made",
    "viqmaProspekts": "Custom prospects",
    "contactDate": "Contact(date)",
    "demoDate": "Demo(date)",
    "sentBy": "Sent by",

    "accidentReason.null": "Not specified",
    "accidentReason.0": "Accident",
    "accidentReason.1": "Incident",
    "accidentReason.2": "Risk observation",
    "question": "Question",
    "previous": "Previous",
    "next": "Next",
    "email": "Email:",
    "mobileSystematicTasksHeader": "Mobile systematic tasks",
    "newMobileSystematicTasksHeader": "New mobile systematic task",
    "mobileSystematicTasksIntroText1": "Here you can create new and change existing templates for Systematic tasks to be handled on the mobile. Click on “Add new” to build a new template (checklist) for e.g. Fire and safety inspection and connect to a systematic task. It is only possible to create one template per systematic task.",
    "mobileSystematicTasksIntroText2": "Those responsible for the task get it on their start page in the mobile version one month before the next task is planned. When the mobile form is completed and saved, the task date is closed, a PDF is created of the completed form and is uploaded under the task (the file name will automatically be \"name of systematic task\" + \"scheduled date of the task\" and can be found under the tab uploaded files).",
    "editMobileSystematicTasksHeader": "Edit mobile systematic task",
    "responsibleForTemplate": "Responsible for template",
    "linkToSystematicTask": "Connect to systematic task",
    "systematicTaskInformationInput": "Enter information",
    "checkboxWithComment": "Checkbox with comment",
    "fieldsForMobileForm": "Fields for mobile form",
    "addFieldForMobileForm": "Add field for mobile form",
    "editFieldForMobileForm": "Edit field for mobile form",
    "addField": "Add field",
    "systematicTask": "Systematic Task",
    "fillInAllRequiredFields": "Fill in all required fields!",
    "dueDate": "Fix at the latest",
    "checkList": "Checklist",
    "taskDate": "Task date",
    "functions": "Functions",
    "donePercentage": "Finished in %",
    "decisionStatus": "Decision status",
    "finishedAt": "Closed at",
    "plannedFinishedAt": "Planned finished at",
    "difficultyCost": "Difficulty/Cost",
    "importanceValue": "Effect/Value",
    "improvementDecision": "Decision",
    "decisionResult": "Results/Decision",
    "evaluationRequired": "Use evaluation",
    "isUsedDeviation": "Used in deviation",
    "isUsed": "Is used",
    "issueHierarchyType": "Type",
    "issueHierarchyType.0": "Deviation type",
    "issueHierarchyType.1": "Error code",
    "issueHierarchyType.2": "Cause code",
    "useReview": "Use evaluation",
    "today": "Today",
    "activateIdea": "Activate idea to activity",
    "confirm.deleteValue": "Are you certain you want to delete {{value}}?",
    "confirm.supplierLink": "Do you really want to send out an external supplier evaluation?",
    "confirm.deleteSkillInfo": "Then the competence is also deleted on the users who have it.",
    "confirm.deletePositionInfo": "Then the position is also deleted on the users who have it, and the position on required competence",

    "deleteValueMessage": "'{{value}}' is used in other modules.",

    "priorityInfo": "Click in the priority matrix to select or change the priority of the activity.",
    "evaluationResult": "Evaluation (Have actions given the desired result?)",
    "reviewText": "Evaluation",
    "evaluatedBy": "Evaluated by",
    "evaluatedAt": "Evaluated at",
    "notifyAuthorizedUser": "Send email to user with autority",
    "authorizedUser": "User with authority",
    "closeImprovementActivity": "Close improvement activity",
    "closeDeviation": "Close deviation",
    "closeAccident": "Close accident/Incident/risk observation",
    "page": "Page",
    "pageSize": "Number per page",
    "solutionProposal": "Solution proposal",
    "disabledResponsibleReminder": "Responsible must be entered and active user to send reminder",
    "disabledSendSupplierLink": "The supplier must have a valid email address entered in their contact information.",
    "sendSupplierLink": "Send link to supplier via VIQMA",
    "adminFieldDisable": "Only users who are admin in module can edit field",
    "onlyAdminSave": "Only users who are admin in module can save",
    "search": "Enter keywords...",
    "dateDone": "Date done",
    "viqmaPower": "Powered by VIQMA System AB",
    "supplierId": "Supplier",
    "customerSupplier": "Customer",
    "reportDate": "Reported at",
    "reviewBeforeDate": "Evaluate before",
    "supplierShouldReply": "Supplier answer",
    "replied": "Answered",
    "departmentId": "Department/Group",
    "locationId": "Location of business",
    "moduleSettings": "Module settings",


    "improvementIdeaListStatus.0": "Activated",
    "improvementIdeaListStatus.4": "Closed",
    "improvementIdeaListStatus.5": "New",
    "improvementIdeaListStatus.6": "Stand by",

    "improvementActivityListStatus.0": "Active",
    "improvementActivityListStatus.1": "Pending decision",
    "improvementActivityListStatus.2": "Decision made",
    "improvementActivityListStatus.3": "Evaluate",
    "improvementActivityListStatus.4": "Closed",
    "improvementActivityListStatus.5": "New",
    "improvementActivityListStatus.6": "Stand by",

    "improvementIdeaDropdownStatus.0": "Activate idea to activity",
    "improvementIdeaDropdownStatus.4": "Close Idea",
    "improvementIdeaDropdownStatus.5": "New",
    "improvementIdeaDropdownStatus.6": "Stand by",

    "improvementDecisionStatus.0": "Responsible user/group has the authority to make decision",
    "improvementDecisionStatus.1": "The activity is sent for decision to another user with authority",
    "improvementDecisionStatus.2": "Decision is made by another user with authority",

    "moduleStatus.1": "Active",
    "moduleStatus.2": "Evaluate",
    "moduleStatus.4": "Closed",

    "issueStatus.1": "Active",
    "issueStatus.2": "Evaluate",
    "issueStatus.3": "Closed",

    "projectActivityStatus.1": "Active",
    "projectActivityStatus.4": "Closed",

    "projectActivityChecklistStatus.1": "No",
    "projectActivityChecklistStatus.4": "Yes",
    "projectActivityChecklistStatus.9": "Inaktiv",

    "deviationMethod": "Deviation method",
    "deviationMethod.0": "PDCA",
    "deviationMethod.1": "8D",

    "teamMembers": "Team members",
    "sendEmailToTeamMembers": "Send email to team members",
    "desiredEffect": "Desired effect",
    "desiredEffectInfo": "Have the implemented actions achieved the desired effect?",
    "desiredEffectComment": "Comment",
    "sendEightDReportToTeamMembers": "Send an email with the completed 8D report to the team",
    "month.1": "1",
    "month.2": "2",
    "month.3": "3",
    "month.4": "4",
    "month.5": "5",
    "month.6": "6",
    "month.7": "7",
    "month.8": "8",
    "month.9": "9",
    "month.10": "10",
    "month.11": "11",
    "month.12": "12",
    "selectAll": "Select all",
    "pieChart": "Pie chart",
    "barChart": "Bar chart",
    "desiredEffectPopUp": "Can only be approved by the person responsible for the deviation.",
    "8dReportPending": "Sending 8D reports",
    "8dReportSuccess": "8D reports sent successfully!",
    "8dReportError": "Failed to send some 8D reports.",
    "service": "Product/Service",
    "quality": "Quality",
    "environment": "Environment",
    "workEnvironment": "Work environment",
    "csr": "CSR",
    "loadBearingStructureWeldingCertificate": "1090/3834",
    "interval": "Interval",
    "assessedDate": "Assessed date",
    "qualityCertificationExpires": "Quality certification expires",
    "environmentCertificationExpires": "Environment certification expires",
    "workEnvironmentCertificationExpires": "Work environment certification expires",
    "csrCertificationExpires": "CSR certification expires",
    "otherCertificationExpires": "Other certificates expires",
    "externalAssessmentSentDate": "Sent",
    "externalAssessmentAnsweredDate": "Responded",
    "actionPlan": "Action plan",
    "organizationNumber": "Orgnr/Idnr",
    "contact": "Contact",
    "zipCode": "Zip code",
    "address": "Address",
    "telephoneNumber": "Telephone number",
    "country": "Country",
    "webpage": "Webpage",
    "swe": "Swedish",
    "eng": "English",
    "contactInformation": "Contact information",
    "keyFigure": "Key figure",
    "internalAssessment": "Internal assessment",
    "externalResponse": "Response to external questions",
    "supplierDeviations": "Supplier deviations",
    "end": "End",
    "supplierQuestionType.1": "Quality",
    "supplierQuestionType.2": "Environment",
    "supplierQuestionType.3": "Workenvironment",
    "supplierQuestionType.4": "CSR",
    "supplierQuestionType.5": "Other",
    "supplierQuestionType.6": "1090/3834",
    "supplierQuestionType.7": "okänd",
    "subtitle": "Subtitle",
    "selfSubtitle": "Self subtitle",
    "points": "Points",
    "lastChanged": "Last changed",
    "remedyEffecieny": "Remedy effecieny",
    "handledInTime": "In time",
    "handledToLate": "Too late",
    "deviationsReportedInTime": "Deviations reported in time",
    "qualityDeviationCost": "Quality deviation cost",
    "accidentAbsence": "Sick absence/Accident",
    "absence": "Absence",
    "accidentEmploymentType": "Form of employment/Accident",
    "accidentCause": "Cause/Accident",
    "accidentConditions": "Conditions/Accident",
    "cause": "Cause",
    "statisticType": "Statistic type",
    "table": "Table",
    "count": "Antal",
    "laborCostInfo": "To calculate labor costs based on hourly cost",
    "deviationTypeYear": "Deviation/Type year",
    "deviationTypeMonth": "Deviation/Type month",
    "accidentTypeYearBar": "Accident/incident/risk observation year barchart",
    "accidentTypeYearPie": "Accident/incident/risk observation year piechart",
    "deviationStatisticStartpage": "Deviation statistic visible on start page",
    "accidentStatisticStartpage": "Accident/incident/risk observation statistic visible on start page",
    "customizeStats": "Customize statistics",
    "customizeStatsInfo": "Use the dropdowns to customize the statistics displayed. Multiple types of statistics can be displayed simultaneously on the page, and the selections made will customize the statistics.",
    "remedyEffecienyStatsInfo": "The statistics are derived from deviations with the status closed or evaluate, and from the fields “Reported at” and ”Closed at” in the deviation form. The statistics in the table show the average number of days to action and the number of deviations.",
    "deviationsReportedInTimeStatsInfo": "The statistics are derived from deviations with the status closed or evaluate, and from the fields “Done before” (if filled in) and “Closed at” in the deviation form. The statistics are divided into those reported on time and too late, and the pie chart shows the percentage.",
    "qualityDeviationCostStatsInfo": "The statistics are derived regardless of the status of registered deviations and from the fields “Cost (SEK)” and “Time (h)” in the deviation form. To get the “Labor cost”, the hourly cost must be specified in “Module settings” on the “Statistics” tab.",
    "accidentEmploymentTypeStatsInfo": "The statistics are derived regardless of the status of registered accidents and from the question about ”Type of employment” in the accident form. Accidents where the question is not specified are also shown in the statistics.",
    "accidentAbsenceStatsInfo": "The statistics are derived regardless of the status of registered accidents and from the question about ”Probable sick leave” in the accident form. Accidents where the question is not specified are also shown in the statistics.",
    "accidentCauseStatsInfo": "The statistics are derived regardless of the status of registered accidents and from the question about ”Cause of accident” in the accident form. Accidents where the question is not specified are also shown in the statistics.",
    "notSpecified": "Not specified",
    "certificate": "Certificate",
    "internalFiles": "Internal files",
    "externalFiles": "External files",
    "internalFilesMessageBody": "Internal uploaded files can´t be seen by the supplier on the supplier evaluation page.",
    "externalFilesMessageBody": "External uploaded files can be seen or uploaded by the supplier on the supplier evaluation page.",
    "hasSupplierConcernMessageBody": "You are in a subsidiary company where the supplier is managed at the parent company level.",
    "hasSupplierConcernAndDevationsMessageBody": "Only supplier deviations are managed at the subsidiary level.",
    "expireCertDate": "Specify the expiry date of certificates/vouchers",
    "affects": "Affects",
    "internalAssessmentInfo": "Click on 'Add new internal assessment' and fill in all the fields in the form with ratings for each question and any comments. The date is automatically registered. For the next assessment, the previous answers will be the starting point. Make any necessary adjustments and save, and a history with an average per question type will be created.",
    "keyFigureInfo": "Key figures can be used to follow e.g. delivery security and purchase volume. Key figures are not visible to the supplier on the supplier evaluating page.",
    "supplierDeviationsInfo": "Supplier deviations show information about which deviations are linked to the supplier in the deviation module.",
    "externalAssessmentInfo": "External questions show an overview of answers given by the supplier in recent years and a line chart with the average value for each type of question.",
    "supplierActionPlanInfo": "Action plans can be used to e.g. plan to contact the supplier, if a credit report is needed or if you are going to visit the supplier. The action plan will appear on the start page of the user responsible for it.",
    "averageScore": "Average score",
    "averageValue": "Average score:  {{value}}",
    "linkSupplierQuestions": "Link to questionform",
    "supplierKeyFigureInfo": "Key figures can be used to follow e.g. delivery security and purchase volume. Key figures are not visible to the supplier on the supplier evaluating page.",
    "supplierAffectsInfo": "Select the areas or certificates that the supplier affects. Your selection will determine which questions are displayed under external questions and internal assessment criteria for the supplier.",
    "supplierIntervalInfo": "The interval is an optional function used to notify admin users in the module on the startpage when it's time to perform an assessment again, as well as to highlight the date in red in the list view. For example, choose 3 years if the supplier has a valid certificate that is valid for 3 more years, otherwise 1 year.",
    "internalQuestions": "Internal questions",
    "externalQuestions": "External questions",
    "accidentSubtype": "Risk code",

    "supplierAffectsError": "At least one of the fields 'quality', 'environment', 'workEnvironment', 'csr', or 'loadBearingStructureWeldingCertificate' must be true",
    "isRegular": "Regular",
    "batchConnectUsers": "Mass connect users",
    "objectNumber": "Object number",
    "externalCompetenceUser": "Administer external users, names, and companies to display external competences (visible only in the competence module)",
    "administrateExternalCompetenceUser": "Administer external users",
    "externalCompetence": "External competencies",
    "supplierDeviationInfo": "You have one deviation at {{value}} which they wish you answer. Answer the deviation by filling out the fiels for cause analysis and corrective action below and push save.",
    "deviationmanagement": "Deviation Management",
    "supplierDeviationReplied": "Thank you! For replying to this deviation at {{value}}",
    "organizationChart": "Organization chart",
    "jobDescription": "Job description",
    "downloadImage": "Download image"


}